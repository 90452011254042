import React, { useContext, useEffect, useRef, useState } from 'react';
import dashboardContext from '../context/dashboard';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import { formatDate } from '../utils/dashboard';

export default function VocabMetrics({dictionary}) {
    const { clusteredData, toggler } = useContext(dashboardContext);
    const [selectedWeek, setSelectedWeek] = useState(null);
    const [processedData, setProcessedData] = useState()
    const [combinedData, setCombinedData] = useState()

    const [cards, setCards] = useState({
        newWordsThisWeek: 0,
        growthPercentage: 0,
        totalCount: 0,
        mostUsedWord: '',
        mostUsedWordCount: 0
    })
    const wordsRef = useRef(null);

    const handlePointClick = (data) => {
    const clickedWeek = data?.activePayload?.[0]?.payload;
    if (clickedWeek && clickedWeek.week !==0 ) {
        const weekData = processedData.current.find(data => data.week === clickedWeek.week)
        setSelectedWeek(weekData);
        updateCards(weekData);
        setTimeout(() => {
            wordsRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 100);
    }
    };

    const updateCards = (selectedWeek = null) => {
        const weekToProcess = selectedWeek || processedData && processedData.current[processedData.current?.length - 1];
        const newWordsThisWeek = weekToProcess?.newWords?.length || 0;
        const currentWeekCount = weekToProcess?.cumulativeWords || 0;
    
        const previousWeekIndex = processedData?.current.findIndex(week => week.week === weekToProcess.week) - 1;
        const previousWeekCount = processedData?.current[previousWeekIndex]?.cumulativeWords || 0;
    
        const growth = ((currentWeekCount - previousWeekCount) / currentWeekCount) * 100;
        const growthPercentage = growth.toFixed(2);
    
        let mostUsedWord = '';
        let mostUsedWordCount = 0;
        if(weekToProcess) {
            const cluster = clusteredData.current.find(item => weekToProcess.week === formatDate(new Date(item.weekStartDate), 'MMM dd, yyyy'))
            if(cluster) {
                cluster.terms.forEach(item => {
                    const word = item.term;
                    const totalCount = item.notes.reduce((sum, note) => sum + note.count, 0);
            
                    if (totalCount > mostUsedWordCount) {
                        mostUsedWord = word;
                        mostUsedWordCount = totalCount;
                    }
                });
            }
        }
    
        setCards({
            newWordsThisWeek,
            growthPercentage,
            totalCount: currentWeekCount,
            mostUsedWord,
            mostUsedWordCount,
        });
    };
    const allWeeksSet = new Set();
    clusteredData.current.forEach(week =>
        allWeeksSet.add(week.weekStartDate)
    );
    clusteredData.other.forEach(cluster =>
        cluster.forEach(week =>
            allWeeksSet.add(week.weekStartDate)
        )
    );

    const allWeeks = Array.from(allWeeksSet).sort(
        (a, b) => new Date(a) - new Date(b)
    );
    const processData = (data) => {
        let result = [{
            week: 0,
            cumulativeWords: 0,
            newWords: []
        }]
        allWeeks.forEach((week) => {
            const weekData = data.find(d => d.weekStartDate === week)
            if(weekData) {
                const cumulativeCount = result[result.length - 1].cumulativeWords + weekData.newWords.length;
                result.push({
                    week: formatDate(new Date(weekData.weekStartDate), 'MMM dd, yyyy'),
                    cumulativeWords: cumulativeCount,
                    newWords: weekData.newWords,
                })                    
            }
            else    
                result.push({
                week: formatDate(new Date(week), 'MMM dd, yyyy'),
                cumulativeWords: result[result.length - 1].cumulativeWords,
                newWords: []
            })
        })
        return result;
    }

    const combineData = (processedData) => {
        if (processedData) {
            const data = allWeeks.map(week => {
                const formattedWeek = formatDate(new Date(week), 'MMM dd, yyyy');
                
                const currentCumulativeWords = 
                    processedData?.current?.find(d => d.week === formattedWeek)?.cumulativeWords || 0;
    
                const otherStudentsCumulativeWords = 
                    processedData?.other?.map(studentData =>
                        studentData.find(d => d.week === formattedWeek)?.cumulativeWords || 0
                    );
    
                const totalWords = 
                    [currentCumulativeWords, ...otherStudentsCumulativeWords].reduce((acc, words) => acc + words, 0);
    
                const averageWords = totalWords / (otherStudentsCumulativeWords.length + 1);
    
                return {
                    week: formattedWeek,
                    current: currentCumulativeWords,
                    ...otherStudentsCumulativeWords.reduce((acc, cumulativeWords, index) => {
                        acc[`student${index + 1}`] = cumulativeWords;
                        return acc;
                    }, {}),
                    average: averageWords.toFixed(0),
                };
            });
    
            const initialEntry = {
                week: 0,
                current: 0,
                average: 0,
            };
    
            processedData?.other?.forEach((_, index) => {
                initialEntry[`student${index + 1}`] = 0;
            });
    
            data.unshift(initialEntry);
            return data;
        }
    };
    

    useEffect(() => {
        const data = processData(clusteredData.current)
        let otherData = [];
        clusteredData.other.forEach(cluster => {
            otherData.push(processData(cluster))
        })
        setProcessedData({current: data, other: otherData})
    },[dictionary]);
    
    useEffect(() => {
        updateCards()
        setCombinedData(combineData(processedData))
    }, [processedData])

    return (
    <div style={{margin: "20px"}}>
        {/* <h2>Vocabulary Growth</h2> */}
        <div ref={wordsRef}>
            <div className='cards-container'>
                <div className='card'>
                    <h3>New {toggler ? 'Phrases' : 'Words'} Discovered</h3>
                    <h2>{cards.newWordsThisWeek}</h2>
                    <p>Total new {toggler ? 'phrases' : 'words'} used this week.</p>
                </div>

                <div className='card'>
                    <h3>Growth</h3>
                    <h2>+{cards.growthPercentage}%</h2>
                    <p>Among total <span style={{color: '#5C84A8', fontWeight: 600}}>{cards.totalCount}</span> unique words used</p>
                </div>

                <div className='card'>
                    <h3>Most Frequent {toggler ? 'Phrase' : 'Word'}</h3>
                    <h2>{cards.mostUsedWord}</h2>
                    <p>Used <span style={{color: '#5C84A8', fontWeight: 600}}>{cards.mostUsedWordCount}</span> number of times</p>
                </div>
            </div>
            <div>
                {selectedWeek && (
                    <div className='new-words-container'>
                        <h3 className='text-primary'>New {toggler ? 'Phrases' : 'Words'} for the Week Starting on {selectedWeek.week}</h3>
                        <ul>
                            {selectedWeek.newWords.map((word, index) => (
                                <li key={index}>{word}</li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
        <ResponsiveContainer width="100%" height={500}>
            <LineChart
                data={combinedData}
                margin={{ top: 20, right: 30, left: 20, bottom: 40 }}
                onClick={handlePointClick}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="week"
                    label={{ value: 'Week', position: 'insideBottom', offset: -20 }}
                    tickMargin={5}
                />
                <YAxis
                    label={{
                        value: 'Vocabulary Growth',
                        angle: -90,
                        position: 'insideLeft',
                        offset: 0,
                    }}
                />
                <Tooltip />
                
                <Legend
                    layout="horizontal"
                    verticalAlign="top"
                    align="right"
                    wrapperStyle={{ top: 0, right: 0, position: 'absolute' }}
                />

                {/* Student's growth line */}
                <Line
                    type="monotone"
                    dataKey="current"
                    stroke="#5C84A8"
                    strokeWidth={3}
                    activeDot={{
                        r: 8,
                    }}
                    name="Your Growth"
                />

                <Line
                    type="monotone"
                    dataKey="average"
                    stroke="#8884d8"
                    strokeWidth={3}
                    activeDot={{
                        r: 8,
                    }}
                    name="Average"
                />

                {processedData?.other?.map((_, index) => (
                    <Line
                        key={`student${index + 1}`}
                        type="monotone"
                        dataKey={`student${index + 1}`}
                        stroke="#cccccc"
                        strokeWidth={2}
                        dot={false}
                        activeDot = {false}
                        tooltipType='none'
                        legendType='none'
                        isAnimationActive={false}
                    />
                ))}
            </LineChart>
        </ResponsiveContainer>
    </div>
    );
}
