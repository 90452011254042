import { useEffect } from 'react';
import sort from '../assets/icons/sort.svg'

export default function WordDictionary({ dictionary, sortedData, updateSortedData, updateDictionary }) {
    const getDate = (timestamp) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' };
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(new Date(timestamp));
        return formattedDate
    }

    const getTotalCount = (notes) => {
        return notes.reduce((total, note) => total + note.count, 0);
    }

    const sortWord = () => {
        let sortedObject = {};
        const terms = [...dictionary]
        if (sortedData.word === 'desc') {
            sortedObject = terms.sort((a, b) => a.term.localeCompare(b.term));
            updateSortedData({ ...sortedData, word: 'asc' })
        }
        else {
            sortedObject = terms.sort((a, b) => b.term.localeCompare(a.term));
            updateSortedData({ ...sortedData, word: 'desc' })
        }
        updateDictionary(sortedObject)
    }

    const sortCount = () => {
        let sortedObject = {};
        const terms = [...dictionary]
        if (sortedData.count === '' || sortedData.count === 'desc') {
            sortedObject = terms.sort((a, b) => getTotalCount(a.notes) - getTotalCount(b.notes));
            updateSortedData({ ...sortedData, count: 'asc' })
        }
        else {
            sortedObject = terms.sort((a, b) => getTotalCount(b.notes) - getTotalCount(a.notes));
            updateSortedData({ ...sortedData, count: 'desc' })

        }
        updateDictionary(sortedObject)
    }
    useEffect(()=> {
        sortWord();
    },[])
    return (
        <div className="table-container">
            <table className="table">
                <thead>
                    <tr>
                        <th onClick={sortWord}><div className='w-1-4th'>Word<img src={sort} alt='sort' width={20} height={20}/></div></th>
                        <th onClick={sortCount}><div>Count<img src={sort} alt='sort' width={20} height={20}/></div></th>
                        <th>Timestamp</th>
                        <th>Notes</th>
                    </tr>
                </thead>
                <tbody>
                    {dictionary.map((item, index) => {
                        return <tr key={index} >
                            <td className='w-1-4th'>{item.term}</td>
                            <td>
                                {getTotalCount(item.notes)}
                            </td>
                            <td>
                                {item.notes.map((note, index) =>
                                    <div style={{ marginBottom: "10px" }} key={index}>{getDate(note.timestamp)}</div>)}
                            </td>
                            <td>
                                {item.notes.map((note, index) =>
                                    <div key={index} style={{ marginBottom: "10px" }}>
                                        <a href={`${process.env.REACT_APP_KF6_URL}/contribution/${note.id}/${note.viewId}`} rel="noreferrer" target='_blank'>{note.title}</a>
                                    </div>
                                )}
                            </td>

                        </tr>
                    }
                    )}
                </tbody>
            </table>
        </div>
    )
}

