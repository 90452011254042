import React, { useState, useEffect } from "react";
import Lottie from "react-lottie";
import files from "../assets/animations/files.json";
import scan from "../assets/animations/scan.json";
import table from "../assets/animations/table.json";
import growth from "../assets/animations/growth.json";
import dashboard from "../assets/animations/dashboard.json";

export default function Animation({ isLoading, onAnimationsComplete }) {
  const [currentAnimation, setCurrentAnimation] = useState(0);
  const [isLongLoading, setIsLongLoading] = useState(false);
  const [showHangOnMessage, setShowHangOnMessage] = useState(false);
  const [showAllSetMessage, setShowAllSetMessage] = useState(false);

  const animations = [
    { data: files, duration: 3000, text: "Gathering Notes", width: 200, height: 200 },
    { data: scan, duration: 3000, text: "Analyzing Words", width: 200, height: 200 },
    { data: table, duration: 3000, text: "Building Word Count", width: 200, height: 200 },
    { data: growth, duration: 3000, text: "Tracking Growth", width: 200, height: 200 },
    { data: dashboard, duration: 3000, text: "Preparing Dashboard", width: 300, height: 200 },
  ];

  useEffect(() => {
    if (isLoading) {
      const timer = setTimeout(() => setIsLongLoading(true), 5000); // Detect long loading after 5s
      return () => clearTimeout(timer);
    } else {
      setIsLongLoading(false); // Reset on loading complete
    }
  }, [isLoading]);

  useEffect(() => {
    if (isLongLoading && currentAnimation === animations.length - 1) {
      const timer = setTimeout(() => setShowHangOnMessage(true), 3000); // Show "Hang On" message after 3s of looping
      return () => clearTimeout(timer);
    } else {
      setShowHangOnMessage(false); // Hide message if loading completes or it's not the last animation
    }
  }, [isLongLoading, currentAnimation]);

  useEffect(() => {
    if (!isLoading && showHangOnMessage) {
      setShowHangOnMessage(false); // Hide "Hang On" message
      setShowAllSetMessage(true); // Show "All Set" message
      const timer = setTimeout(() => setShowAllSetMessage(false), 3000); // Remove after 3s
      return () => clearTimeout(timer);
    }
  }, [isLoading, showHangOnMessage]);

  useEffect(() => {
    const duration = isLoading
      ? animations[currentAnimation].duration
      : animations[currentAnimation].duration / 3;

    const timer = setTimeout(() => {
      if (currentAnimation === animations.length - 1) {
        if (!isLoading && onAnimationsComplete) onAnimationsComplete();
        return;
      }
      setCurrentAnimation((prev) => prev + 1);
    }, duration);

    return () => clearTimeout(timer);
  }, [currentAnimation, isLoading, onAnimationsComplete]);

  const defaultOptions = {
    loop: isLongLoading && currentAnimation === animations.length - 1,
    autoplay: true,
    animationData: animations[currentAnimation].data,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="flex-col-center h-screen">
      <Lottie
        options={defaultOptions}
        height={animations[currentAnimation].height}
        width={animations[currentAnimation].width}
      />
      <h2 className="text-primary">{animations[currentAnimation].text}</h2>
      {showHangOnMessage && (
        <div className="flex-col-center text-secondary">
          <h3 className="m-0 p-0">Hang On!</h3>
          <p>This might take a while for the first time or when there are lots of notes to process!</p>
        </div>
      )}
      {showAllSetMessage && (
        <div className="flex-col-center text-success">
          <h3 className="m-0 p-0">All Set!</h3>
          <p>Your dashboard is ready to go!</p>
        </div>
      )}
    </div>
  );
}
