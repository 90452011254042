import WordDictionary from "../components/WordDictionary"
import { useEffect, useState, useRef, useContext } from 'react';
import { clusterByWeek } from "../utils/dashboard";
import dashboardContext from "../context/dashboard";
import VocabMetrics from "./VocabMetrics";

const Main = ({loadNewUser}) => {
    const { allDictionary, community, clusteredData, selectedWeek, active, setClusteredData, setSelectedWeek, toggler, setToggler } = useContext(dashboardContext)
    const [sortedData, setSortedData] = useState({ word: 'desc', count: '', timestamp: '' })
    const viewRef = useRef()
    const [dictionary, setDictionary] = useState()
    const [selectedView, setSelectedView] = useState('')
    const [loadContent, setLoadContent] = useState(false)
    // const { data: dictionaryResult, loading, error } = useQuery(Dictionary.getDictionary,
    //     { variables: { authorId: role === 'writer' ? studentsResult?.id : selectedUser ? selectedUser : studentsResult[0]?.id, communityId: communityId } })
    useEffect(() => {
        let clusterResponse = [];
        let otherClusters = [];
        const result = toggler ? allDictionary?.current.phrases : allDictionary?.current.words
        let otherDictionaries = [];
        allDictionary?.other.forEach(dict => {
            otherDictionaries.push(toggler ? dict.phrases : dict.words)
        })
        if (result) {
            const viewFilter = (data) => {
                let dict = []
                for (const item of data) {
                    for (const note of item.notes) {
                        if (note.viewId === selectedView)
                            dict.push(item)
                    }
                }
                return dict
            }
            if (selectedView) {
                const dict = viewFilter(result)
                setDictionary(dict)
                clusterResponse = clusterByWeek(dict)
                otherDictionaries.forEach(otherDict => {
                    const dict = viewFilter(otherDict)
                    otherClusters.push(clusterByWeek(dict));
                })
            }
            else {
                setDictionary(result)
                clusterResponse = clusterByWeek(result)
                otherDictionaries.forEach(dict => {
                    otherClusters.push(clusterByWeek(dict));
                })
            }
            setClusteredData({current: clusterResponse, other: otherClusters})
            setSelectedWeek(clusterResponse[0]?.weekStartDate)
        }
        else {
            setDictionary({})
            setClusteredData({})
        }
        setLoadContent(false);
    }, [allDictionary, selectedView, setClusteredData, setSelectedWeek, toggler])

    const updateDictionary = (updatedDictionary) => {
        if (active === 'Dictionary') {
            setDictionary(updatedDictionary);
        } else if (active === 'Progress') {
            const updatedCurrent = clusteredData.current.map(cluster =>
                cluster.weekStartDate === selectedWeek
                    ? { ...cluster, terms: updatedDictionary }
                    : cluster
            )
            setClusteredData({...clusteredData, current: updatedCurrent});
        }
    };
    
    if (loadNewUser || !dictionary)
        return <div className="flex-center h-full">Loading....</div>
    return (
        <div className="content-main">
            <div className="content-head">
                <h2>{active === 'Dictionary' ? 'Dictionary' : active === 'Progress' ? 'Weekly Progress' : active === 'Dashboard' ? 'Dashboard' : ''}</h2>
                <div className="flex-between-center">
                    <div className="toggle">
                        <div className="togglelabel word" style={{ color: !toggler && '#5C84A8', fontWeight: !toggler && 700 }}>Words</div>
                        <input type="checkbox" id="temp" checked={toggler} onChange={() => {
                            setLoadContent(true);
                            setToggler(!toggler);
                        }} />
                        <label className="togglelabel" htmlFor="temp" style={{ color: toggler && '#5C84A8', fontWeight: toggler && 700 }}>Phrases</label>
                    </div>
                    <select ref={viewRef} value={selectedView} onChange={(event) => {
                        setSelectedView(event.target.value);
                        setLoadContent(true);
                    }}>
                        <option value=''>All Views</option>
                        {community?.views.map((view, index) => <option key={index} value={view.id}>{view.title}</option>)}
                    </select>
                </div>
            </div>
            <div className="divider"></div>
            {loadContent ?
                <div className="flex-center h-full">Loading....</div>
                : active === 'Dashboard' 
                    ? dictionary.length === 0 
                        ?   <div className="flex-center h-full">No progress reported in this view</div>
                        :   <VocabMetrics dictionary={dictionary} /> 
                    : dictionary.length === 0 
                        ?   <div className="flex-center h-full">No notes in this view</div>
                        :   <WordDictionary 
                                dictionary={ 
                                    active === 'Dictionary' 
                                        ? dictionary 
                                        : active === 'Progress' 
                                        ? clusteredData.current.find(cluster => cluster.weekStartDate === selectedWeek).terms 
                                        : []
                                } 
                                updateDictionary={updateDictionary} 
                                sortedData={sortedData} 
                                updateSortedData={(updatedSortedData) => setSortedData(updatedSortedData)
                                } 
                            />}
        </div>
    );
}

export default Main;